<template>
    <div> 
        <mbs-search-select-items
            color="blue"
            bar_type="warehouse"
            :show_toolbar="true"
            :show_table="false"
            />
        <!-- //DIALOGS -->
        <div></div>
    </div>
</template> 
<script> 

    import InfiniteScroll from 'vue-infinite-scroll';
    import DATA from "../../../plugins/DATA";
    import {mapState} from "vuex" 
    const PAGE_NAME = "SELECT_ITEMS"

    export default {
        props:['show_toolbar','show_table','height','search_key',
        'items','color','textColor','opacity','title','text','outlet_code',
        'selected_card','item_order'],
        directives: {
            InfiniteScroll,
        },
        data(){
            return{
                show:false,   
                item_multiple_select:false,   
                dialog_edit_selected_item:false,   
                menu_selling_mode:false, 
                menu_selected_card:false, 
                menu_select_order_option:false, 
                menu_item_order:false, 
                current_selected_item:null,   
                input:{},   
                focused_field:null,
                input_discount_amount:null,   
                input_discount_percentage:null,   
                inputLoading:false,
                longClickTimer: null,
                longClickDetected: false,

                
                selectedRows: [],
                lastSelectedRow: -1,
                editingCell: null,

                pagination: null,
                paginatedItems: [],
                isLoading: false,
                currentPage: 1,
                totalPages: 1,
                pageSize: 20,

                sellingModels:["Retail Price","Wholesale Price"], 
                selling_model:null,
                search_item:'', 
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('ON_KEY_DOWN', this.ON_KEY_DOWN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){   
            this.loadMore();
            this.INITIALIZE_SELLING_MODE()
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                
               
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 
            }), 

            ItemsData(){
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                return JoinedItems?JoinedItems:Items
            },
            UseableItems(){
                let items = this.items
                let ItemsData = this.ItemsData
                return ItemsData?ItemsData:items

            }, 
            SearchedItems(){
                let UseableItems = this.UseableItems
                let search_key = this.search_key
                if(!UseableItems){return null}
                if(!search_key){return UseableItems}
                let filtered_item = UseableItems.filter(item=>{
                    let search = this.MBS.actions.TEXT_UP(item.name)
                    if (!search) {
                        search=""
                    }
                    let index = search.indexOf(this.MBS.actions.TEXT_UP(search_key))
                    return index == -1?false:true
                })
                console.log(filtered_item,'filtered_item');
                return filtered_item

            },  
            CheckedItems(){
                const ItemsData = this.ItemsData
                if(!ItemsData){return null}
                let filtered = ItemsData.filter(item=>item.checked)
                return filtered
            },

            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            SELECTED_CARD(){
                let card = this.selected_card
                return card
            }, 
            ITEM_ORDER(){
                let order = this.item_order
                return order
            }, 
            
              
        },
        methods:{ 
            async loadMore(event) {
                let items = this.SearchedItems
                let isLoading = this.isLoading
                let currentPage = this.currentPage
                let totalPages = this.totalPages
                let pageSize = this.pageSize
                let pagination = this.pagination
                let paginatedItems = this.paginatedItems

                console.log(event,1111111111);
               
                // if (this.isLoading || this.currentPage >= this.totalPages) return;

                console.log(222222);
                this.isLoading = true;

                try { 
                    this.pagination = this.MBS.actions.pagination(items,currentPage,pageSize) 
                    this.paginatedItems = this.pagination?this.paginatedItems.concat(this.pagination.list) :null
                    this.currentPage++
                    console.log(pagination,'newData......');
                     
                } catch (error) {
                    console.error('Error loading more data:', error);
                }

                this.isLoading = false;
            },
            handleKeyDown(event) {
                // if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                //     event.preventDefault();
                // }
            },
            isEditing(rowIndex, columnIndex) {
            return this.editingCell && this.editingCell.row === rowIndex && this.editingCell.column === columnIndex;
            },
            startEditing(rowIndex, columnIndex) {
            this.editingCell = { row: rowIndex, column: columnIndex };
            this.$nextTick(() => this.$refs.editField.focus());
            },
            stopEditing(rowIndex, columnIndex) {
            this.editingCell = null;
            },
            selectRow(index) {
                if (this.lastSelectedRow !== -1 && this.$root.shiftKey) {
                    const start = Math.min(this.lastSelectedRow, index);
                    const end = Math.max(this.lastSelectedRow, index);
                    this.selectedRows = [];

                    for (let i = start; i <= end; i++) {
                    this.selectedRows.push(i);
                    }
                } else if (this.selectedRows.includes(index)) {
                    this.selectedRows.splice(this.selectedRows.indexOf(index), 1);
                } else {
                    this.selectedRows.push(index);
                } 
                this.lastSelectedRow = index;
            },


            startLongClick() {
                this.longClickTimer = setTimeout(() => {
                    this.longClickDetected = true;
                    this.item_multiple_select = !this.item_multiple_select;
                    console.log('Long click detected');
                }, 1000); 
            },
            endLongClick() {
                clearTimeout(this.longClickTimer);
                setTimeout(() => { 
                    this.longClickDetected = false;
                }, 10);
            },

            RESET_ALL_DATA(){ 
                try { 
                    Object.assign(this.$data, this.$options.data())  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RESET_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            },

            INITIALIZE_SELLING_MODE(){
                try {
                    let CompanySettings = this.CompanySettings
                    if (!CompanySettings) {
                        
                    } else {
                        if (CompanySettings.both_selling_mode) {
                            this.selling_model = ""
                        }if (CompanySettings.only_retail_model) {
                            this.selling_model = "Retail Price"
                        }if (CompanySettings.only_wholesale_model) {
                            this.selling_model = "Wholesale Price"
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_SELLING_MODE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    const sold_price = this.toNumber(item.sold_price)
                    const discount = this.toNumber(item.discount)
                    const quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_SELECT_ITEM(item,index){
                try {  
                    if (!this.longClickDetected) {
                        this.$emit("item_selected",item,index) 
                        this.ON_SELECT([item])
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT(items){
                try {  
                    this.RESET_ALL_DATA()
                    this.$emit("selected",items)  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            UPDATE_SELECTED_ITEM(){
                try { 
                    let input = this.input
                    // this.current_selected_item=item
                    this.dialog_edit_selected_item = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            CONFIRM_REMOVE_ITEM(selected_item,index){
                try { 
                    this.dialog_edit_selected_item = false
                    this.$emit("confirm_remove_item",selected_item,index)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(data){
                try { 
                    this.$emit("remove_item",data)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CHECK_ITEM(item,index){
                try { 
                    // this.selected_items[index] = {
                    //     ...item,
                    //     checked: item.check? true :false
                    // }
                    console.log(item);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FOCUS_INPUT(field){
                this.focused_field = field 
            },
            ON_SEARCH(selling_model){
                try {    
                    if (!selling_model) { 
                        this.menu_selling_mode = true
                    }
                    console.log(this.menu_selling_mode,'menu_selling_mode....')
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            //---------------[MAIN FUNCTIONS]------------   
            ON_KEY_DOWN(key,time){
                try {
                    if (key=='Enter') {   

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_KEY_DOWN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                                console.log((this.input[field_value]?this.input[field_value]:'')+value,'****......');
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1);

                            console.log(this.input,'input......');
                            console.log(value,'value......');
                        }
                        
                    }
                }
            }, 

        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_KEY_DOWN', this.ON_KEY_DOWN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            CompanySettings(value){
                if (value) {
                    this.INITIALIZE_SELLING_MODE() 
                }
            },
            item_multiple_select(value){
                this.$emit("item_multiple_select",value?true:false)
            },
            CheckedItems(value){
                this.$emit("checked_items",value)
            },
            search_item(value){ 
                if (value) {
                    // this.ADD_ITEM(value)  
                    console.log(value,'sea........')
                    this.$emit("searched_item_key",value)
                    this.$emit("input",{
                        selling_model:this.selling_model,
                        searched_item_key:value,
                    }) 
                    setTimeout(() => {
                        this.search_item=''
                    }, 50);
                }
            }, 
            selling_model(value){ 
                this.$emit("input",{
                    selling_model:value
                }) 
            }, 
        } 

    }
</script>
<style>
    .selected {
        background-color: #a8dadc;
    }
    .checked {
        background-color: #dadada;
    }
    
</style> 